<script setup lang="ts">
import { FirstPurchaseProps } from "./FirstPurchase.props"
import { sm, md, lg } from "~/utils/breakpoints"
import { socialLogin } from "~/config/social-login"
import { navigateToLoginSignupPage } from "~/utils/loginSignup/loginSignup"

const props = defineProps<FirstPurchaseProps>()

const bgImage = computed(() => {
  if (!!props.bgImageSmall && sm.value) return props.bgImageSmall
  else if (!!props.bgImageMedium && md.value && !lg.value)
    return props.bgImageMedium

  return props.bgImageLarge
})
const { openModal } = useModal()
</script>

<template>
  <div
    class="
      banner-discount_promo-first_purchase
      relative
      overflow-hidden
      px-4
      py-8
      md:py-6
    "
  >
    <UtilsWrapperImage
      v-if="bgImage"
      v-bind="bgImage"
      :key="bgImage.image.url"
      class="wrapper-image-full-container-centered absolute inset-0 -z-10"
    />

    <div
      class="md:grid-container z-10 flex flex-col gap-4 text-center text-white"
    >
      <div
        class="
          flex flex-col
          items-start
          justify-center
          gap-2
          text-left
          md:col-span-4 md:col-start-2
          md:gap-4
          lg:gap-6
        "
      >
        <p v-if="title" class="elephant-bold md:mammoth-bold">
          {{ title }}
        </p>

        <UtilsMarkdown
          v-if="text"
          class="beaver md:pig-medium markdown__link-underlined"
          :content="text"
        />
      </div>

      <div class="flex flex-col justify-center md:col-span-5 md:col-start-7">
        <UtilsButton
          v-if="logInOrRegisterButton"
          class="mb-4 w-full md:mb-6 lg:mb-5"
          v-bind="logInOrRegisterButton"
          theme="contained-grey"
          order="last"
          @click="navigateToLoginSignupPage"
        >
          <template #icon
            ><UtilsIcon name="Email.svg" color="black-80" class="h-5 w-5"
          /></template>
        </UtilsButton>

        <p class="beaver-bold md:pig-bold mb-4 md:mb-6 lg:mb-5">
          {{ $t("banner.discountPromo.or") }}
        </p>

        <div class="flex flex-col gap-2 md:grid md:grid-cols-3 lg:gap-4">
          <UtilsButton
            v-if="logInButton1"
            class="custom-btn-whitespace w-full"
            v-bind="logInButton1"
            theme="outlined-white"
            order="last"
            :path="socialLogin('FACEBOOK')"
            force-self
          >
            <template #icon>
              <UtilsIcon
                name="Facebook.svg"
                color="white"
                class="inline-block"
              />
            </template>
          </UtilsButton>

          <UtilsButton
            v-if="logInButton2"
            class="custom-btn-whitespace w-full"
            v-bind="logInButton2"
            theme="outlined-white"
            order="last"
            :path="socialLogin('GOOGLE')"
            force-self
          >
            <template #icon>
              <UtilsIcon name="Google.svg" color="white" />
            </template>
          </UtilsButton>

          <UtilsButton
            v-if="logInButton3"
            class="custom-btn-whitespace w-full"
            v-bind="logInButton3"
            theme="outlined-white"
            order="last"
            :path="socialLogin('APPLE')"
            force-self
          >
            <template #icon>
              <UtilsIcon name="Apple.svg" color="white" />
            </template>
          </UtilsButton>
        </div>
      </div>
    </div>
  </div>
</template>
