<script setup lang="ts">
import type { ComponentEditorialBannerProps } from "./ComponentEditorialBanner.props"
import { BannerDeliveryPaymentsProps } from "~/components/Organisms/Cart/BannerDeliveryPayments.props"
import { NewsletterProps } from "~/components/Banner/Newsletter.props"
import { GridItemsProps } from "../Banner/WhoWeAre/GridItems.props"
import { socialLogin } from "~/config/social-login"

defineProps<ComponentEditorialBannerProps>()

const { t } = useI18n()
const isLogged = useIsLogged()
const { customerServiceHomepage } = useBannerHelp()

const isAlreadyVisited = useStatefulCookie("already-visited").value

const lastViewedProductsCookie: Ref<undefined | string[]> =
  useStatefulCookie("lastProductsViewed")

const paymentDelivery = computed((): BannerDeliveryPaymentsProps => {
  return {
    banners: [
      {
        iconType: "delivery",
        title: t("homepage.deliveryBanners.banner1.title"),
        text: t("homepage.deliveryBanners.banner1.text")
      },
      {
        iconType: "box",
        title: t("homepage.deliveryBanners.banner2.title"),
        text: t("homepage.deliveryBanners.banner2.text")
      }
    ],
    backgroundColor: "yellow-main"
  }
})

const newsletter = computed((): NewsletterProps => {
  return {
    caption: t("services.newsletter.caption"),
    title: t("services.newsletter.title"),
    ctaText: t("services.newsletter.ctaText"),
    unsubscribeText: t("services.newsletter.unsubscribeText"),
    confirmationSubscribeTitle: t(
      "services.newsletter.confirmationSubscribeTitle"
    ),
    confirmationSubscribeText: t(
      "services.newsletter.confirmationSubscribeText"
    )
  }
})

const firstPurchase = computed(() => {
  return {
    logInOrRegisterButton: {
      text: "Accedi o Registrati"
    },
    logInButton1: {
      text: "Utilizza",
      path: socialLogin("FACEBOOK"),
      forceSelf: true
    },
    logInButton2: {
      text: "Utilizza",
      path: socialLogin("GOOGLE"),
      forceSelf: true
    },
    logInButton3: {
      text: "Utilizza",
      path: socialLogin("APPLE"),
      forceSelf: true
    }
  }
})

const WhoWeAre = computed(
  (): GridItemsProps => ({
    title: t("whoWeArePage.WhoWeAreBanner.title"),
    cards: [
      {
        iconType: "shield",
        title: t("whoWeArePage.WhoWeAreBanner.cards.reliability.title"),
        text: t("whoWeArePage.WhoWeAreBanner.cards.reliability.text")
      },
      {
        iconType: "chat",
        title: t("whoWeArePage.WhoWeAreBanner.cards.availability.title"),
        text: t("whoWeArePage.WhoWeAreBanner.cards.availability.text")
      },
      {
        iconType: "leaf",
        title: t("whoWeArePage.WhoWeAreBanner.cards.sustainability.title"),
        text: t("whoWeArePage.WhoWeAreBanner.cards.sustainability.text")
      }
    ]
  })
)
</script>

<template>
  <div class="cms-component-editorial-banner">
    <RailsUtility v-if="data.bannerType === 'Generic infos'" v-bind="data" />

    <BannerHelpCustomerService
      v-if="data.bannerType === 'Need help?'"
      v-bind="{ ...customerServiceHomepage, ...data }"
    />

    <div class="padded" v-if="data.bannerType === 'Consulting'">
      <BannerHelpConsultingPharma v-bind="data" />
    </div>

    <OrganismsCartBannerDeliveryPayments
      v-if="data.bannerType === 'Payment & Delivery'"
      v-bind="paymentDelivery"
      class="md:margined"
    />

    <TextBlocksUtility v-if="data.bannerType === 'Login'" />

    <BannerNewsletter
      class="padded"
      v-if="data.bannerType === 'Newsletter'"
      v-bind="newsletter"
      :bgImageLarge="data.bgImage"
    />

    <BannerPromo
      v-bind="data"
      :background-image="{
        ...data.backgroundImage,
        preload: !!preloadImages,
        imgAttrs: { fetchpriority: preloadImages ? 'high' : 'auto' }
      }"
      v-if="data.bannerType === 'Promo'"
    />

    <BannerDiscountPromoFirstPurchase
      v-bind="{ ...data, ...firstPurchase }"
      :bg-image-large="data.bgImage"
      v-if="
        data.bannerType === 'First purchase' &&
        !isLogged &&
        isAlreadyVisited &&
        lastViewedProductsCookie?.length
      "
    />

    <BannerWhoWeAreGridItems
      v-bind="WhoWeAre"
      v-if="data.bannerType === 'Our values'"
    />

    <BannerHelpHorizontalNeedHelp v-if="data.bannerType === 'Support'" />
  </div>
</template>
