<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { FormKitNode } from "@formkit/core"
import { socialLogin } from "~/config/social-login"
import { iconsSrcMap, IconType } from "./Utility.iconMapping"
import { md } from "~/utils/breakpoints"

const { sendEventErrorForm } = useGA4SiteEvents()
const { t } = useI18n()

const form = ref()
const emailField = ref("")
const passwordField = ref("")

const isLoading = ref(false)

const benefits = computed((): { icon: IconType; text: string }[] => [
  {
    icon: "priceTag",
    text: t("textBlock.utility.benefit1")
  },
  {
    icon: "shoppingBag",
    text: t("textBlock.utility.benefit2")
  },
  {
    icon: "gift",
    text: t("textBlock.utility.benefit3")
  } //,
  // {
  //   icon: "star",
  //   text: t("textBlock.utility.benefit4")
  // }
])

const { handleRegistration, errorMessage } = useLoginRegistration()

const signupHandler = () => {
  if (!emailField?.value || !passwordField?.value) return

  isLoading.value = true

  handleRegistration({
    email: emailField.value,
    password: passwordField.value,
    // TODO: check why there is no subscribe to newsletter and it's manual here
    subscribeNewsletter: false
  })
}

watch(errorMessage, (newVal) => {
  isLoading.value = false
  if (newVal) {
    setTimeout(() => {
      errorMessage.value = undefined
    }, 5000)
  }
})

const { emailValidation } = useLoqate()
const emailCheck = async (node: FormKitNode) =>
  await emailValidation(node.value as string)

const { valid, focusInvalidInputs } = useFocusInvalidInputs(form)
</script>

<template>
  <section class="utility-wrapper md:grid-standard flex flex-col">
    
<FormKitLazyProvider config-file="true">
<div
      class="
        padded
        md:left-padded
        flex flex-1 flex-shrink-0 flex-col
        bg-gradient-to-r
        from-green-main
        to-custom-green
        py-8
        text-white
        md:col-span-8
        md:ml-[-71px]
        md:py-12
        md:pr-0
        lg:ml-[-228px]
      "
    >
      <div class="flex h-full w-full flex-col items-start justify-center gap-8">
        <p class="elephant-bold">{{ t("textBlock.utility.title") }}</p>

        <p class="pig">
          {{ t("textBlock.utility.description") }}
        </p>

        <div class="flex flex-col gap-8">
          <div
            v-for="(item, index) in benefits"
            :="item"
            :key="`benefit${index}`"
            class="flex items-center gap-4"
          >
            <div v-if="item.icon" class="icon-circle">
              <UtilsIcon
                v-bind="iconsSrcMap[item.icon]"
                color="tiffany-main"
                class="h-6 w-6 md:h-8 md:w-8"
              />
            </div>

            <span class="pig-bold md:hyppo-bold">
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-span-4 flex-1 px-4 py-8 md:pl-6 md:pt-20">
      <FormKit
        ref="form"
        type="form"
        id="registrationUtilityForm"
        @submit-invalid="sendEventErrorForm('registrationUtilityForm')"
        :actions="false"
      >
        <div class="mb-5 flex w-full flex-col gap-2 md:mb-7 md:gap-4">
          <TextfieldsEmail
            v-model="emailField"
            :label="t('textBlock.utility.insertYourEmail')"
          />

          <TextfieldsInputText
            v-model="passwordField"
            type="password"
            name="password"
            :validation="[
              ['matches', /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{6,25}$/],
              ['required']
            ]"
            is-required
            :label="
              $t('dialogsAndModals.organism.loginAndRegistration.passwordField')
            "
            :validation-messages="{ matches: '', required: '' }"
          />

          <TextfieldsPasswordCheck
            v-if="passwordField"
            theme="site"
            :password-field="passwordField"
            class="mb-4"
          />

          <p v-if="errorMessage" class="mouse py-2 text-negative-main">
            {{ errorMessage }}
          </p>
          <UtilsButton
            @click="valid ? signupHandler() : focusInvalidInputs()"
            :text="t('textBlock.utility.register')"
            class="custom-btn-pig-medium mb-2 w-full"
          >
            <template #icon>
              <UtilsLoader v-if="isLoading" class="mr-2" />
            </template>
          </UtilsButton>

          <TextBlocksAcceptConditions />
        </div>
      </FormKit>

      <UtilsMarkdown
        :content="
          md
            ? t('textBlock.utility.or')
            : t('textBlock.utility.socialRegistration')
        "
        class="beaver py-7 text-black-80 md:pb-4 md:pt-3 md:text-center"
      />

      <div class="flex flex-col gap-2 md:grid md:grid-cols-3 md:flex-row">
        <UtilsButton
          :path="socialLogin('GOOGLE')"
          :text="t('textBlock.utility.useIt')"
          class="
            custom-btn-pig-medium
            w-full
            md:[&>span]:h-full
            md:[&>span]:flex-wrap
          "
          theme="outlined-green"
          order="last"
          force-self
        >
          <template #icon>
            <UtilsIcon name="GoogleColored.svg" />
          </template>
        </UtilsButton>

        <UtilsButton
          :path="socialLogin('FACEBOOK')"
          :text="t('textBlock.utility.useIt')"
          class="
            custom-btn-pig-medium
            w-full
            md:[&>span]:h-full
            md:[&>span]:flex-wrap
          "
          theme="outlined-green"
          order="last"
          force-self
        >
          <template #icon>
            <UtilsIcon name="Facebook.svg" class="text-custom-facebook" />
          </template>
        </UtilsButton>

        <UtilsButton
          :path="socialLogin('APPLE')"
          :text="t('textBlock.utility.useIt')"
          class="
            custom-btn-pig-medium
            w-full
            md:[&>span]:h-full
            md:[&>span]:flex-wrap
          "
          theme="outlined-green"
          order="last"
          force-self
        >
          <template #icon>
            <UtilsIcon name="AppleBlack.svg" class="mx-1" />
          </template>
        </UtilsButton>
      </div>
    </div>
</FormKitLazyProvider>

  </section>
</template>

<style scoped lang="scss">

.icon-circle {
  @apply flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full  bg-tiffany-10 md:h-12 md:w-12;
}

:deep(.formkit-messages) {
  @apply hidden;
}

</style>
